<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import RevenueAnalytics from "../dashboard/revenue";
import MaleAndFemaleReport from "./MaleAndFemaleReport.vue";
import Sources from "../dashboard/sources";
import Transaction from './table.vue';
import {GetDataMethods, notificationMethods} from "@/state/helpers";
import CustomerDate from "@/components/CustomerDate.vue";
import BarChart from "@/views/pages/customer/BarChartIdsCustomers.vue";


export default {
  components: {
    BarChart,
    CustomerDate,
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    MaleAndFemaleReport,
    Sources,
    Transaction
  },
  data() {
    return {
      title: "Dashboard",
      labelsIds: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "9",
      ],
      dataIds: [65, 65, 65, 45, 56, 80, 50, 20],
      statData: [
        {
          title: "......",
          icon: "",
          value: "0",
          subvalue: " 0% "
        },
        {
          title: "......",
          icon: "",
          value: "0",
          subvalue: " 0% "
        },
        {
          title: "......",
          icon: "",
          value: "0",
          subvalue: " 0% "
        }
      ],
      male: {
        count: 0,
        percentage: 0
      },
      female: {
        count: 0,
        percentage: 0
      },
      customerOrder: {
        count: 0,
        percentage: 0
      },
      customerMoney: {
        count: 0,
        percentage: 0
      },
      customerBill: {
        count: 0,
        percentage: 0
      },
      customerEnquiry: {
        count: 0,
        percentage: 0
      },
      data_countries: false,
      customerDateAsDaly: false,
      customerDateAsWeekly: false,
      customerDateAsMonthly: true,

      orders: {
        series: [65],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#004aff"],
          stroke: {
            lineCap: "round"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%"
              },
              track: {
                margin: 0
              },

              dataLabels: {
                show: false
              }
            }
          }
        }
      },


      barChart: {
        series: [{
          data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
        }],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#34c38f'],
          grid: {
            borderColor: '#f1f1f1',
          },
          xaxis: {
            categories: ["asdasd", "sdasdsa"],
          }
        }
      },
      barChartIdsCustomers: {
        series: [{
          name: 'Team A',
          type: 'column',
          data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
        }],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: [0, 2, 4],
            curve: 'smooth'
          },
          plotOptions: {
            bar: {
              columnWidth: '50%'
            }
          },
          colors: ['#1cbb8c', '#fcb92c', '#5664d2'],
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          markers: {
            size: 0
          },
          legend: {
            offsetY: -10,
          },
          xaxis: {
            type: 'datetime'
          },
          yaxis: {
            title: {
              text: 'Points',
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0) + " points";
                }
                return y;

              }
            }
          },
          grid: {
            borderColor: '#f1f1f1'
          }
        }
      },
      items: [
        {
          text: "Nazox"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getCustomersIdsCount(t) {
      this.getData("get_customers_ids_by_order_type/" + t).then((res) => {
        this.labelsIds = res.data.data.customer_ids.labels
        this.dataIds = res.data.data.customer_ids.data

      })
    },
    getStatData() {
      this.getData("get_customer_stat_data").then((res) => {
        var data = res.data.data
        this.statData = [
          {
            title: "Count All Customers",
            icon: "ri-account-box-line",
            value: data.all_customer.count,
            subvalue: data.all_customer.percentage + "%"
          },
          {
            title: "Customers Confirmed",
            icon: "ri-check-double-line",
            value: data.customers_confirmed.count,
            subvalue: data.customers_confirmed.percentage + "%"
          },
          {
            title: "Customers Blocked",
            icon: "ri-close-circle-line",
            value: data.customers_blocked.count,
            subvalue: data.customers_blocked.percentage + "%"
          }

        ]
        this.male = data.male
        this.female = data.female

        this.barChart.series = [{
          data: data.countries.data
        }]
        this.customerOrder = data.customer_orders
        this.customerMoney = data.customer_money
        this.customerEnquiry = data.customer_enquiry
        this.customerBill = data.customer_bill
        this.barChart.chartOptions.xaxis.categories = data.countries.labels
        this.data_countries = true
      })
    },
    changeTypeDate(t) {
      if (t == 'daly') {
        this.customerDateAsDaly = true;
        this.customerDateAsMonthly = false;
        this.customerDateAsWeekly = false;

      }
      if (t == 'weekly') {
        this.customerDateAsDaly = false;
        this.customerDateAsMonthly = false;
        this.customerDateAsWeekly = true;

      }
      if (t == 'monthly') {
        this.customerDateAsDaly = false;
        this.customerDateAsMonthly = true;
        this.customerDateAsWeekly = false;

      }
    },

  },
  mounted() {
    this.getStatData()
    this.getCustomersIdsCount("Order")
  }
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-xl-8">
        <Stat :stat-data="statData"/>
        <RevenueAnalytics class="h-auto"/>
      </div>
      <div class="col-xl-4">
        <MaleAndFemaleReport :count-male="male" :count-female="female"/>

        <Sources/>

      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Customer Orders</h4>
            <div class="row text-center">
              <div class="col-sm-3">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apexchart
                        class="apex-charts"
                        height="60"
                        wight="60"
                        dir="ltr"
                        :series="[customerOrder.percentage]"
                        :options="orders.chartOptions"
                    ></apexchart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Customers Orders</p>
                  <h5 class="mb-0">{{ customerOrder.count }}</h5>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apexchart
                        class="apex-charts"
                        height="60"
                        wight="60"
                        dir="ltr"
                        :series="[customerMoney.percentage]"
                        :options="orders.chartOptions"
                    ></apexchart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Customers Money</p>
                  <h5 class="mb-0">{{ customerMoney.count }}</h5>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apexchart
                        class="apex-charts"
                        height="60"
                        wight="60"
                        dir="ltr"
                        :series="[customerBill.percentage]"
                        :options="orders.chartOptions"
                    ></apexchart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Customers Bills</p>
                  <h5 class="mb-0">{{ customerBill.count }}</h5>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apexchart
                        class="apex-charts"
                        height="60"
                        wight="60"
                        dir="ltr"
                        :series="[customerEnquiry.percentage]"
                        :options="orders.chartOptions"
                    ></apexchart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Customers Bank</p>
                  <h5 class="mb-0">{{ customerEnquiry.count }}</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button @click="changeTypeDate('daly')" type="button" class="btn btn-sm btn-light">daly</button>
                <button @click="changeTypeDate('weekly')" type="button" class="btn btn-sm btn-light ">Weekly</button>
                <button @click="changeTypeDate('monthly')" type="button" class="btn btn-sm btn-light">Monthly</button>
              </div>
            </div>
            <h4 class="card-title mb-4">Date Analytics</h4>
            <CustomerDate :show="customerDateAsDaly" type="daly"></CustomerDate>
            <CustomerDate :show="customerDateAsMonthly" type="monthly"></CustomerDate>
            <CustomerDate :show="customerDateAsWeekly" type="weekly"></CustomerDate>
          </div>


        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button @click="getCustomersIdsCount('orders')" type="button" class="btn btn-sm btn-light">Orders</button>
                <button @click="getCustomersIdsCount('money')" type="button" class="btn btn-sm btn-light ">Money</button>
                <button @click="getCustomersIdsCount('bills')" type="button" class="btn btn-sm btn-light">Providers</button>
                <button @click="getCustomersIdsCount('enquiries')" type="button" class="btn btn-sm btn-light">Bank</button>
              </div>
            </div>
            <h4 class="card-title mb-4">End ID Customers</h4>

            <!-- Bar Chart -->
            <BarChart  :labels="labelsIds" :data="dataIds"/>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">
        <Transaction/>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-12">Customers With Countries</h4>
            <!-- Bar Chart -->
            <apexchart
                v-if="data_countries"
                class="apex-charts"
                height="1500"
                type="bar"
                dir="ltr"
                :series="barChart.series"
                :options="barChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>

    </div>
  </Layout>

</template>