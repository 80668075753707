<script>
/**
 * Earning Reports component
 */
export default {
  props:{
    countMale : {
      default:{percentage : 0, count: 0}
    },
    countFemale : {
      default:{percentage : 0, count: 0}
    }
  },

  data() {
    return {
      WeeklyEarning: {
        series: [72],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#2c91d0"],
          stroke: {
            lineCap: "round"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%"
              },
              track: {
                margin: 0
              },
              dataLabels: {
                show: false
              }
            }
          }
        }
      },
      monthlyEarning: {
        series: [65],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#de3dd6"],
          stroke: {
            lineCap: "round"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%"
              },
              track: {
                margin: 0
              },

              dataLabels: {
                show: false
              }
            }
          }
        }
      }
    };
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Male And Female Report</h4>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="mb-3">
                <apexchart
                    class="apex-charts"
                    height="60"
                    wight="60"
                    dir="ltr"
                    :series="[countMale.percentage]"
                    :options="WeeklyEarning.chartOptions"
                ></apexchart>
              </div>
              <p class="text-muted text-truncate mb-2">Male Customers</p>
              <h5 class="mb-0">{{countMale.count}}</h5>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mt-5 mt-sm-0">
              <div class="mb-3">
                <apexchart
                    class="apex-charts"
                    height="60"
                    wight="60"
                    dir="ltr"
                    :series="[countFemale.percentage]"
                    :options="monthlyEarning.chartOptions"
                ></apexchart>
              </div>

              <p class="text-muted text-truncate mb-2">Female Customers</p>
              <h5 class="mb-0">{{ countFemale.count }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>