<script>

import {GetDataMethods, notificationMethods} from "@/state/helpers";


export default {

  props:{
    type:{
      default : "daly"
    },
    show:{
      default : false
    }
  },
  data() {
    return {

        data_countries : false,
      barChart : {
        series: [{
          data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
        }],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#34c38f'],
          grid: {
            borderColor: '#f1f1f1',
          },
          xaxis: {
            categories: [],
          }
        }
      },
    };
  },
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getStatData() {
      this.getData("get_customer_date_as/" + this.type).then((res) => {
        var data = res.data.data

        this.barChart.series=[{
          data: data.customers.data
        }]

        this.barChart.chartOptions.xaxis.categories = data.customers.labels
        this.data_countries = true
      })
    }

  },
  mounted() {
    this.getStatData()
  }
};
</script>

<template>

  <div>
    <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
    <apexchart

        v-if="show"
        class="apex-charts"
        height="280"
        dir="ltr"
        :series="barChart.series"
        :options="barChart.chartOptions"
    ></apexchart>
  </div>

</template>
